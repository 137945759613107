import { __decorate } from "tslib";
import { Component, Vue, Watch } from 'vue-property-decorator';
import { Getter } from 'vuex-class';
// components
import Btn from '@/components/Button/Btn.vue';
import Sidebar from '@/components/Elements/Sidebar.vue';
import ProfileCard from '@/components/ProfileCard/ProfileCard.vue';
import TextInput from '@/components/FormInputs/TextInput/TextInput.vue';
import CoverImage from '@/components/Elements/CoverImage.vue';
let ConversationPage = class ConversationPage extends Vue {
    constructor() {
        super(...arguments);
        // create comment
        this.form = {
            to_user_id: this.$route.params.slug,
            message: '',
        };
        this.errors = {
            to_user_id: '',
            message: '',
        };
        // Width Of Sidebar container
        this.sidebarContainerWidth = 0;
        // Is the sidebar fixed
        this.isSidebarFixed = false;
        this.loading = true;
        this.coverImage = '';
        this.interval = null;
        this.sendingDisabled = false;
    }
    get isDesktop() {
        return this.mediaSize >= 2;
    }
    async onConversationUpdate() { }
    async mounted() {
        await this.getConversationBySlug();
        let self = this;
        this.interval = setInterval(function () {
            self.getConversationBySlug();
        }, 10000);
        if (this.conversationMember.id) {
            this.setCoverImage();
        }
        this.loading = false;
        await this.$store.dispatch('engagements/setEngagementType', {
            engagementType: '',
            engagementSlug: '',
        });
        this.handleWidthChange();
        // scroll to bottom of chat
        this.scrollToBottomOfChat();
        // If we have been passed a query param we add this to the message
        if (this.$route.query.content) {
            this.form.message = this.$route.query.content;
        }
    }
    // When the component is created track the scroll position
    created() {
        window.addEventListener('resize', this.handleWidthChange);
    }
    // When the component is destroyed stop tracking to ensure lower memory use
    beforeDestroy() {
        clearInterval(this.interval);
        window.removeEventListener('resize', this.handleWidthChange);
    }
    // When the component is destroyed stop tracking to ensure lower memory use
    destroyed() {
        clearInterval(this.interval);
        window.removeEventListener('resize', this.handleWidthChange);
    }
    // Update the scroll pos once scrolling
    handleWidthChange() {
        const sidebarRef = this.$refs.sidebarContainer;
        this.sidebarContainerWidth = sidebarRef.clientWidth;
    }
    onSidebarFixed(value) {
        this.isSidebarFixed = value;
    }
    async getConversationBySlug() {
        await this.$store.dispatch('conversations/getConversationBySlug', this.$route.params.slug);
    }
    isSender(id) {
        return this.profile.id === id;
    }
    setCoverImage() {
        if (!this.coverImage && this.conversationMember.images && this.conversationMember.images.length > 0) {
            this.coverImage = this.conversationMember.images[0].public_url;
        }
        else {
            this.coverImage = '/img/bg/inbox-bg.jpg';
        }
    }
    // Format an Africa Geographic share link into a clickable link
    formatMessage(message) {
        message = message.replace(/(\r)*\n/g, '<br>');
        if (message.includes('[AGLINK')) {
            let matches = message.match(/\[AGLINK-(.*?)\]/);
            // Format the link into a clickable string
            if (matches) {
                return message.replace(/\[AGLINK-(.+?)\]/g, `<a href="${matches[1]}">Click here to view - ${matches[1]}</a>`);
            }
        }
        // Make urls clickable
        var expression = /[(http(s)?):\/\/(www\.)?a-zA-Z0-9@:%._\+~#=]{2,256}\.[a-z]{2,6}\b([-a-zA-Z0-9@:%_\+.~#?&//=]*)/ig;
        var regex = new RegExp(expression);
        if (message.match(regex)) {
            let matches = message.match(regex);
            // Format the link into a clickable string
            if (matches) {
                return message.replace(regex, `<a target="_blank" class="underline" href="${matches[0]}">${matches[0]}</a>`);
            }
        }
        // Fall through to just sending the message back
        return message;
    }
    async message() {
        if (this.sendingDisabled)
            return;
        this.sendingDisabled = true;
        try {
            this.form.to_user_id = this.conversation.to_user_id ? this.conversation.to_user_id : this.$route.params.slug;
            await this.$store.dispatch('conversations/createMessage', this.form);
            await this.getConversationBySlug();
            this.scrollToBottomOfChat();
            this.form.message = '';
            this.sendingDisabled = false;
        }
        catch (error) {
            // console.log(error)
            this.sendingDisabled = false;
        }
    }
    async deleteMessage(id) {
        await this.$store.dispatch('conversations/deleteMessage', id);
        await this.getConversationBySlug();
    }
    scrollToBottomOfChat() {
        // scroll to bottom of chat
        const messagesRef = this.$refs.messagesRef;
        messagesRef.scrollTop = messagesRef.scrollHeight;
    }
    async refresh() {
        this.loading = true;
        await this.getConversationBySlug();
        setTimeout(() => {
            this.scrollToBottomOfChat();
        }, 100);
        this.loading = false;
    }
};
__decorate([
    Getter('activeConversation', { namespace: 'conversations' })
], ConversationPage.prototype, "conversation", void 0);
__decorate([
    Getter('activeConversationMember', { namespace: 'conversations' })
], ConversationPage.prototype, "conversationMember", void 0);
__decorate([
    Getter('getProfile', { namespace: 'user' })
], ConversationPage.prototype, "profile", void 0);
__decorate([
    Getter('mediaSize', { namespace: 'app' })
], ConversationPage.prototype, "mediaSize", void 0);
__decorate([
    Getter('getSupportUser', { namespace: 'user' })
], ConversationPage.prototype, "supportUser", void 0);
__decorate([
    Getter('getSafariExpert', { namespace: 'user' })
], ConversationPage.prototype, "safariExpert", void 0);
__decorate([
    Watch('conversation')
], ConversationPage.prototype, "onConversationUpdate", null);
ConversationPage = __decorate([
    Component({
        components: {
            Btn,
            Sidebar,
            ProfileCard,
            TextInput,
            CoverImage,
        },
        metaInfo() {
            return {
                title: 'Africa Geographic | conversation',
                meta: [
                    {
                        name: 'description',
                        content: 'Africa Geographic conversation',
                    },
                ],
                link: [{ rel: 'canonical', href: `${process.env.VUE_APP_URL}/chats` }],
            };
        },
    })
], ConversationPage);
export default ConversationPage;
