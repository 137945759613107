import { __decorate } from "tslib";
import { Component, Prop, Vue } from "vue-property-decorator";
import ProfileCard from '@/components/ProfileCard/ProfileCard.vue';
import Btn from '@/components/Button/Btn.vue';
let ConversationCard = class ConversationCard extends Vue {
    constructor() {
        super(...arguments);
        this.showDeleteModal = false;
    }
    async goToConversation() {
        await this.$store.dispatch('conversations/setActiveConversationMember', this.conversation.user);
        this.$router.push({ path: `/chat/${this.conversation.user.id}` });
    }
    showDeleteConfirmation() {
        this.showDeleteModal = true;
    }
    hideDeleteConfirmation() {
        this.showDeleteModal = false;
    }
    async deleteConversation() {
        try {
            this.showDeleteModal = false;
            await this.$store.dispatch('conversations/deleteConversation', this.conversation.user.id);
            await this.$store.dispatch('conversations/getConversations');
        }
        catch (e) {
            console.log(e);
        }
    }
};
__decorate([
    Prop()
], ConversationCard.prototype, "conversation", void 0);
ConversationCard = __decorate([
    Component({
        components: {
            ProfileCard,
            Btn
        }
    })
], ConversationCard);
export default ConversationCard;
