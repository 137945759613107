import { __decorate } from "tslib";
import { Component, Prop, Vue } from 'vue-property-decorator';
let SearchInput = class SearchInput extends Vue {
};
__decorate([
    Prop()
], SearchInput.prototype, "value", void 0);
__decorate([
    Prop()
], SearchInput.prototype, "id", void 0);
__decorate([
    Prop()
], SearchInput.prototype, "label", void 0);
__decorate([
    Prop()
], SearchInput.prototype, "placeholder", void 0);
__decorate([
    Prop()
], SearchInput.prototype, "error", void 0);
SearchInput = __decorate([
    Component
], SearchInput);
export default SearchInput;
