import { __decorate } from "tslib";
import { Component, Vue } from 'vue-property-decorator';
import { Getter } from 'vuex-class';
import ProfileService from '@/services/user';
// components
import Btn from '@/components/Button/Btn.vue';
import ConversationCard from '@/views/Conversations/Components/ConversationCard/ConversationCard.vue';
import TextInput from '@/components/FormInputs/TextInput/TextInput.vue';
import SearchInput from '@/components/FormInputs/SearchInput/SearchInput.vue';
import CoverImage from '@/components/Elements/CoverImage.vue';
import SelectInput from '@/components/FormInputs/SelectInput/SelectInput.vue';
import Pagination from '@/components/Pagination/Pagination.vue';
let Conversations = class Conversations extends Vue {
    constructor() {
        super(...arguments);
        this.loading = true;
        this.coverImageDesc = "Messages between you, fellow app members and team AG.";
    }
    async mounted() {
        await this.$store.commit('conversations/SET_PER_PAGE', 100);
        await this.$store.dispatch('conversations/getConversations');
        this.loading = false;
        if (this.$store.getters['auth/loggedIn']) {
            await ProfileService.updateLastActivity("messages");
        }
    }
    async loadMore() {
        this.loading = true;
        let perPage = this.$store.state.conversations.perPage;
        let newVal = perPage + 100;
        await this.$store.commit('conversations/SET_PER_PAGE', newVal);
        await this.$store.dispatch('conversations/getConversations');
        this.loading = false;
    }
};
__decorate([
    Getter('getConversations', { namespace: 'conversations' })
], Conversations.prototype, "conversations", void 0);
Conversations = __decorate([
    Component({
        components: {
            Btn,
            ConversationCard,
            TextInput,
            SearchInput,
            CoverImage,
            SelectInput,
            Pagination,
        },
        metaInfo() {
            return {
                title: 'Africa Geographic | conversations',
                meta: [
                    {
                        name: 'description',
                        content: 'Africa Geographic conversations',
                    },
                ],
                link: [{ rel: 'canonical', href: `${process.env.VUE_APP_URL}/chats` }],
            };
        },
    })
], Conversations);
export default Conversations;
